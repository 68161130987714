import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { AISearchTitleIcon } from '../../../../assets/svgs/aiSearchTitleIcon';
import SearchIcon from '../../../../assets/svgs/searchIcon';
import { ThumbsDownIcon } from '../../../../assets/svgs/thumbsDownIcon';
import { ThumbsUpIcon } from '../../../../assets/svgs/thumbsUpIcon';
import SantizedHTMLRawData from '../../../../utils/sanitizeHTML';
import Accordion from '../../../shared/AccordianSearch/Accordion';
import { CustomInput } from '../../../shared/CustomInput';
import Loader from '../../../shared/Loader/loader';
import { ModalComponent } from '../../../shared/Modal/Modal';
import Skeleton from '../../../shared/Skeleton/skeleton';
import AiSearchAccordionHeader from '../aiSearchAccordionHeader/index';
import AISummaryContainer from '../aisumaryContainer';

import CustomModalBody from './customModal/customModalBody';
import { CustomModalFooter } from './customModal/customModalFooter';
import CustomModalHeader from './customModal/customModalHeader';

import style from '../../aiSearch.module.scss';

const ITEMS_COUNT = 3;

enum MODALTYPE {
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down'
}
enum BUTTONTYPE {
  SUBMIT = 'submit',
  CLOSE = 'close'
}
interface ModalContent {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
}

type ModalContentMap = {
  [key: string]: ModalContent;
};
interface FeedBack {
  positive: boolean | null;
  negative: boolean | null;
}
interface Product {
  id?: string;
  productUrl?: string;
  brand?: string;
  sku?: string;
  summary?: string;
  category?: string[];
  subcategory?: string[];
  description?: string;
}

interface ProductData {
  products: Product[];
  summary: string;
}
const ModalBody: React.FC<{
  initialData?: any;
  productData?: ProductData;
  searchItem?: string;
  setSearchItem?: (e: any) => void;
  fetchProductIds?: (question?: string) => void;
  loading?: boolean;
  data?: any;
  productListExist?: boolean;
  productCount?: number | null;
  aiLevelSummary?: string;
  setAILevelSummary?: ((summary: string) => void) | undefined;
  isApiCallDone?: boolean;
  setisApiCallDone?: (isApiCallDone: boolean) => void;
}> = ({
  initialData = [],
  productData = { products: [], summary: '' },
  searchItem,
  setSearchItem,
  fetchProductIds,
  loading,
  data,
  productListExist,
  productCount,
  aiLevelSummary,
  setAILevelSummary,
  isApiCallDone
}) => {
  const [showMore, setShowMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = React.useState<string | null>(null);

  useEffect(() => {
    if (!initialData.length || !productData?.products?.length) {
      setShowMore(false);
    }
  }, [initialData, productData]);
  useEffect(() => {
    if (setAILevelSummary) {
      setAILevelSummary(
        productListExist
          ? `${productData?.summary || ''} I could not find any matching products. Please modify or rephrase your question and try again.`
          : productData?.summary || ''
      );
    }
  }, [productData, productListExist, setAILevelSummary]);
  const [feedBack, setFeedBack] = useState<FeedBack>({
    positive: null,
    negative: null
  });
  const [positiveComment, setPositiveComment] = useState<string>('');
  const [negativeComment, setNegativeComment] = useState<string>('');
  const openModal = (modalType: string): void => {
    setFeedBack({
      positive: modalType === MODALTYPE.THUMBS_UP ? true : null,
      negative: modalType === MODALTYPE.THUMBS_DOWN ? false : null
    });

    setModalType(modalType);
    setIsModalOpen(true);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
    setFeedBack({
      positive: null,
      negative: null
    });
    setModalType('');
    setPositiveComment('');
    setNegativeComment('');
  };
  const onChange = (e: any): void => {
    const value = e.target.value;

    if (modalType === MODALTYPE.THUMBS_UP) {
      setPositiveComment(value);
    }

    if (modalType === MODALTYPE.THUMBS_DOWN) {
      setNegativeComment(value);
    }
  };
  const onSubmitHandler = useCallback(
    (buttonType?: string): void => {
      if (!Object.keys(data).length) {
        return;
      }

      const getFeedbackAndComment = (modalType: string | null): any => {
        if (modalType) {
          const feedback =
            modalType === MODALTYPE.THUMBS_UP
              ? feedBack.positive
              : modalType === MODALTYPE.THUMBS_DOWN
                ? feedBack.negative
                : null;
          const comment =
            modalType === MODALTYPE.THUMBS_UP
              ? positiveComment
              : modalType === MODALTYPE.THUMBS_DOWN
                ? negativeComment
                : null;

          return { feedback, comment };
        }
      };
      const { feedback, comment } = getFeedbackAndComment(modalType);

      if (data?.submitFeedback) {
        const { submitFeedback } = data;

        if (buttonType === BUTTONTYPE.SUBMIT) {
          submitFeedback(feedback, comment);
        }

        if (buttonType === BUTTONTYPE.CLOSE) {
          submitFeedback(feedback, '');
        }
      }

      closeModal();
    },
    [data, feedBack, positiveComment, negativeComment, modalType]
  );
  const modalContent: ModalContentMap = {
    [MODALTYPE.THUMBS_UP]: {
      header: <CustomModalHeader />,
      body: <CustomModalBody value={positiveComment} onChange={onChange} />,
      footer: <CustomModalFooter onSubmit={onSubmitHandler} />
    },
    [MODALTYPE.THUMBS_DOWN]: {
      header: <CustomModalHeader />,
      body: <CustomModalBody value={negativeComment} onChange={onChange} />,
      footer: <CustomModalFooter onSubmit={onSubmitHandler} />
    }
  };
  const getModalContent = (): ModalContent =>
    modalType ? modalContent[modalType] : { header: null, body: null, footer: null };
  const handleShowMore = (): void => {
    setShowMore(true);
  };
  const displayedProducts = useMemo<Product[]>(() => {
    const products = productData?.products || [];

    return showMore || products.length <= ITEMS_COUNT ? products : products.slice(0, ITEMS_COUNT);
  }, [showMore, productData]);
  const handleChange = (e: any): void => {
    if (setSearchItem) {
      setSearchItem(e.target.value);
    }
  };
  const mapCategories = (categories: string[]): any =>
    categories.length && categories[0]?.replace(/,\s*/g, ' ');

  return (
    <div>
      <div className={style.modalBodyContainer}>
        <div className={style.searchInputContainer}>
          <div className={style.customInputField}>
            <CustomInput
              searchIcon={<SearchIcon />}
              placeholder="Drywall with an EPD, recycled content and LEED credits from USG and Certainteed"
              className={style.inputField}
              value={searchItem}
              onChange={handleChange}
            />
            <div
              className={style.searchButton}
              onClick={() => {
                fetchProductIds && fetchProductIds(searchItem);
              }}>
              {loading ? <Loader /> : 'Search'}
            </div>
          </div>
        </div>
      </div>
      {productCount && (
        <div
          className={
            style.productCountText
          }>{`I found ${productCount.toLocaleString()} matching products.`}</div>
      )}
      {(!!initialData?.length || !!productData?.products?.length) && (
        <div className={style.searchResultsCount}>
          Here are {initialData?.length || productData?.products?.length} matching results.  Note,
          this is not a comprehensive set of matching products and more may be available
        </div>
      )}
      {aiLevelSummary && (
        <div className={style.aiSummarySection}>
          <AISummaryContainer
            title="AI Search Assistant:"
            Icon={<AISearchTitleIcon strokeColor={'black'} />}
            description={aiLevelSummary || ''}
            mainSummary
          />
        </div>
      )}

      <section className={style.searchBodyContentContainer}>
        {!!displayedProducts?.length &&
          displayedProducts.map((item: any, index: number) => (
            <section key={item.id} className={style.searchBodyContainer}>
              <Accordion
                className={style.aiSearchAccordion}
                isAiSearch
                onClick={() => {
                  if (data?.trackFn) {
                    data?.trackFn({
                      productURL: item['product_url'] || '',
                      brand: item['brand-ident'] || item['brand-name'],
                      sku: item['sku'] || '',
                      order: index + 1,
                      hostName: window?.location?.hostname || ''
                    });
                  }
                }}
                headerContent={<AiSearchAccordionHeader items={item} />}>
                <div className={style.productSummarySection}>
                  <section className={style.searchBodyheader}>
                    {item?.summary && (
                      <div className={style.aiProductSummarySection}>
                        <div className={style.productSummary}>
                          <AISummaryContainer
                            title="AI Search Assistant:"
                            Icon={<AISearchTitleIcon strokeColor={'black'} aiSummary />}
                            description={item.summary || ''}
                            productSummary
                          />
                        </div>
                      </div>
                    )}

                    <div className={style.searchBodyheaderContent}>
                      {item.category && (
                        <div className={style.searchBodyheaderContentCategory}>
                          <p className={style.searchBodyheaderContentLable}>Category:</p>

                          <p className={style.searchBodyheaderContentValue}>
                            {mapCategories(item.category || [])}
                          </p>
                        </div>
                      )}
                      {item?.subcategory && (
                        <div className={style.searchBodyheaderContentCategory}>
                          <p className={style.searchBodyheaderContentLable}>Sub Category:</p>
                          <p className={style.searchBodyheaderContentValue}>
                            {mapCategories(item.subcategory || [])}
                          </p>
                        </div>
                      )}
                    </div>
                    {item?.description && (
                      <p className={style.searchBodyheaderContentP}>
                        <SantizedHTMLRawData content={item?.description || ''} />
                      </p>
                    )}
                  </section>
                </div>
              </Accordion>
            </section>
          ))}
      </section>

      {initialData &&
        initialData?.slice(0, ITEMS_COUNT)?.map((el: any, index: number) => (
          <section key={index} className={style.searchBodyContainer}>
            <Accordion className={style.aiSearchAccordion} isAiSearch headerContent={<Skeleton />}>
              <div></div>
            </Accordion>
          </section>
        ))}

      {productData?.products && productData?.products?.length > ITEMS_COUNT && !showMore && (
        <div className={style.aiSearchShowMore} onClick={handleShowMore}>
          Show more
        </div>
      )}
      {isApiCallDone && (
        <div className={style.aiGeneratedResultContainer}>
          <p className={style.aiGeneratedResultText}>
            {'This result was generated by AI. Was this helpful?'}
          </p>
          <div className={style.thumbsIconContainer}>
            <div onClick={() => openModal('thumbs_up')}>
              <ThumbsUpIcon />
            </div>
            <div onClick={() => openModal('thumbs_down')}>
              <ThumbsDownIcon />
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <ModalComponent
          isOpen={isModalOpen}
          setIsModalOpen={closeModal}
          showCrossIcon
          modalHeader={getModalContent()?.header || null}
          modalBody={getModalContent()?.body || null}
          modalFooter={getModalContent()?.footer || null}
        />
      )}
    </div>
  );
};

export default memo(ModalBody);
