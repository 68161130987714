import React from 'react';

import { AISearchTitleIcon } from '../../../../assets/svgs/aiSearchTitleIcon';
import AISummaryContainer from '../aisumaryContainer';

const ModalHeader: React.FC = () => (
  <AISummaryContainer
    title="AI Search"
    Icon={<AISearchTitleIcon strokeColor={'black'} />}
    description="Try our new AI Search assistant to find any product you need in seconds."
  />
);

export default ModalHeader;
