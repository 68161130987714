import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundUp } from 'react-icons/io';

import Barcode from '../../assets/svgs/barcode';
import CashIcon from '../../assets/svgs/cash';
import Energy from '../../assets/svgs/energy';
import { Global } from '../../assets/svgs/global';
import Info from '../../assets/svgs/info';
import ManufactureLocation from '../../assets/svgs/manufactureLocation';
import OverallSaving from '../../assets/svgs/overallSaving';
import Rating from '../../assets/svgs/rating';
import SkuIcon from '../../assets/svgs/sku';
import UnitsIcon from '../../assets/svgs/units';
import WaterSaving from '../../assets/svgs/waterSavings';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { useTopScrollArrow } from '../../hooks/useTopScroller';
import { funFactObject } from '../../utils/impactSummary/funFact';
import { columns } from '../../utils/projectEvalTableColumns';
import Accordion from '../shared/Accordian/Accordion';
import AccordionHeader from '../shared/AccordionHeaderContent/AccordionHeader';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import { Box } from '../shared/Box/box';
import FallbackContainer from '../shared/ErrorBoundary/FallbackContainer';
import IconBox from '../shared/IconBox/iconBox';
import { PostiveImpactSummaryCard } from '../shared/PostiveImpactSummaryCard/postiveImpactSummaryCard';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';
import { TopScroller } from '../shared/TopScroller/TopScroller';
import Table from '../Table/Table';

import Header from './Header';
import Navbar from './Navbar';
import ProjectDetails from './ProjectDetails';
import StatisticItem from './StatisticItem';

import style from './projectEvaluation.module.scss';

export interface ISummary {
  cost: number;
  sku: number;
  units: number;
}

export interface IImpact {
  qunatity: number;
  icon: React.ReactElement;
  start: string;
  middle: string;
  end: string;
  bolder: string;
  hasBackground?: boolean;
}
export const gridIcons: { [key: string]: JSX.Element } = {
  rating_system: <Rating isEvalPage />,
  savings: <OverallSaving />,
  water_savings: <WaterSaving />,
  water: <WaterSaving />,
  energy: <Energy />,
  'Overall Savings': <OverallSaving />,
  'Water Savings': <WaterSaving />,
  'Energy Impact Calculator': <Energy />,
  location: <ManufactureLocation />,
  cash: <CashIcon isEvalPage />,
  overall_savings_icon: <OverallSaving />
};
const StatisticsContainer = ({ summary }: { summary: ISummary }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={style.statisticsContainer}>
      <div className={`${style.statistics} statisticsPdf`}>
        <StatisticItem
          title={t('projectEval.statisticsContainer.totalSKUs')}
          amount={summary.sku}
          icon={<SkuIcon />}
        />
        <StatisticItem
          title={t('projectEval.statisticsContainer.totalUnits')}
          amount={summary.units}
          icon={<UnitsIcon />}
        />
        <StatisticItem
          className={style.statisticIconsBg}
          symbol={'$'}
          title={t('projectEval.statisticsContainer.totalCost')}
          amount={summary.cost}
          icon={<CashIcon />}
        />
      </div>
    </div>
  );
};
const ProjectEvaluation = ({
  data,
  pdfRef,
  backToProject,
  trackFn,
  projectName,
  downloadCSV
}: {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
  projectName: Record<string, any> | undefined;
  backToProject: () => {};
  trackFn: (action: string) => {};
  downloadCSV: any;
}): JSX.Element => {
  const [summaryData, setSummaryData] = useState<any>(null);
  const [funFact, setFunFact] = useState<any>([]);
  const [contributingUnitsData, setContributingUnitsData] = useState<number>(0);
  const [showErrorBoundaryinPDF, setShowErrorBoundaryinPDF] = useState<any>({});
  const screenWidth = useScreenWidth();
  const isTopScroller = useTopScrollArrow();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(projectName?.preferred_language || 'en-US');
  }, [i18n, projectName]);

  useEffect(() => {
    if (data) {
      setSummaryData({
        sku: data?.products.length || 0,
        cost: data?.products.reduce((acc: any, curr: any) => acc + curr?.total_cost || 0, 0),
        units: data?.products.reduce((acc: any, curr: any) => acc + curr?.quantity || 0, 0)
      });

      if (!!data?.fun_facts && Object.keys(data?.fun_facts).length !== 0) {
        const { factsObject, contributingUnits } = funFactObject(data?.fun_facts);

        setContributingUnitsData(contributingUnits);
        setFunFact(factsObject);
      }
    }
  }, [data]);

  useEffect(() => {
    trackFn('views project eval page');
  }, [trackFn]);

  return (
    <div className={style.sustainabilitySummaryContainer}>
      <div className={`${style.sustainabilitySummary} sustainabilitySummaryHeaderHeight`}>
        <FallbackContainer
          component="Navbar"
          showErrorBoundaryinPDF={showErrorBoundaryinPDF}
          setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
          <Navbar projectName={projectName || ''} backToProject={backToProject} />
        </FallbackContainer>
        <FallbackContainer
          component="Header"
          showErrorBoundaryinPDF={showErrorBoundaryinPDF}
          setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
          <Header
            projectName={projectName || ''}
            pdfRef={pdfRef}
            name={data?.project?.name || ''}
            trackFn={trackFn}
            address={data?.project?.address || ''}
            downloadCSV={downloadCSV}
          />
        </FallbackContainer>
        <div className={style.divider}></div>
        <FallbackContainer
          component="ProjectDetails"
          showErrorBoundaryinPDF={showErrorBoundaryinPDF}
          setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
          <ProjectDetails
            postalCode={data?.project?.['postal_code'] || data?.project?.location}
            clientName={data?.project?.['client_name'] || ''}
            projectSize={data?.project?.size || ''}
            address={data?.project?.address || ''}
            description={data?.project?.description || ''}
            name={data?.project?.name || ''}
          />
        </FallbackContainer>
        {summaryData && (
          <FallbackContainer
            component="StatisticsContainer"
            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
            <StatisticsContainer summary={summaryData} />
          </FallbackContainer>
        )}
      </div>
      <div
        className={
          data?.project?.address?.length > 30 ||
          (data?.project?.['client_name']?.length > 30 && screenWidth < 500)
            ? style.marginTop
            : data?.project?.address?.length < 30 ||
                (data?.project?.['client_name']?.length < 30 && screenWidth < 500)
              ? style.headerMargin
              : style.defaultTopMargin
        }></div>
      {!!funFact?.length && (
        <div className={style.ratingContainer}>
          <Box>
            <>
              <div className={style.funfactContainer}>
                <IconBox
                  icon={<Global />}
                  title={'Positive Impacts on the Planet'}
                  className={style.iconBoxLeftPadding}
                />
                <div className={style.unitsInfoSection}>
                  <div className={style.iconBoxInfoSection}>
                    <p className={`iconBoxInfoSectionPdfContent`}>
                      {(contributingUnitsData &&
                        (contributingUnitsData * 100)?.toFixed(0).toLocaleString()) ||
                        0}
                      % of Units
                    </p>
                    <Info />
                  </div>
                </div>
              </div>

              <div className={`${style.divider} ${style.dividerColor}`}></div>
              <div className={style.postiveImpactSummaryCardContainer}>
                {funFact?.map((el: any, _index: number) => (
                  <FallbackContainer
                    component="PostiveImpactSummaryCard"
                    showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                    setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                    <PostiveImpactSummaryCard item={el} />
                  </FallbackContainer>
                ))}
              </div>
            </>
          </Box>
        </div>
      )}
      <div className={style.spacing}>
        <FallbackContainer
          component="Table"
          showErrorBoundaryinPDF={showErrorBoundaryinPDF}
          setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
          <Table
            data={data?.products}
            columns={columns}
            isScrollable={true}
            tableStyling={style.evaluationsTable}
          />
        </FallbackContainer>
        {!!data?.evaluations &&
          data?.evaluations?.map((item: any, index: number) => (
            <FallbackContainer
              component="Accordion"
              showErrorBoundaryinPDF={showErrorBoundaryinPDF}
              setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
              <Accordion
                evaluation
                key={index}
                headerContent={
                  <FallbackContainer
                    component="AccordionHeader"
                    showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                    setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                    <AccordionHeader
                      icon={gridIcons[item?.icon] || <UnitsIcon />}
                      title={item?.name || ''}
                      key={`${item?.name}-${index}`}
                    />
                  </FallbackContainer>
                }>
                <div>
                  <div className={style.productTable}>
                    {item.details?.map((row: any, evalIndex: number) => (
                      <FallbackContainer
                        component="AccordionRow"
                        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                        <AccordionRow
                          title={row?.title || ''}
                          value={row?.value || ''}
                          type={typeof row?.value || ''}
                          creditName={row?.display_id || ''}
                          key={`row-${evalIndex}`}
                          isLightContent
                          index={evalIndex}
                          dataLength={item?.details?.length}
                          regWall={data?.regWall}
                        />
                      </FallbackContainer>
                    ))}
                  </div>
                </div>
              </Accordion>
            </FallbackContainer>
          ))}
        {!!data?.calculators &&
          data?.calculators?.map((item: any, index: number) => (
            <FallbackContainer
              component="Accordion"
              showErrorBoundaryinPDF={showErrorBoundaryinPDF}
              setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
              <Accordion
                evaluation
                key={index}
                headerContent={
                  <FallbackContainer
                    component="AccordionHeader"
                    showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                    setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                    <AccordionHeader
                      icon={gridIcons[item?.name] || ''}
                      title={item?.name || ''}
                      key={`${item?.name}-${index}`}
                      ratingSystem
                    />
                  </FallbackContainer>
                }>
                <div>
                  <div className={style.productTable}>
                    <AccordionRow value={'values based on project parameters'} customAccordionRow />
                    {item.details?.map((row: any, evalIndex: number) => (
                      <FallbackContainer
                        component="AccordionRow"
                        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                        <AccordionRow
                          title={row?.name || ''}
                          value={row?.value || ''}
                          type={typeof row?.value || ''}
                          key={`row-${evalIndex}`}
                          isLightContent
                          index={evalIndex}
                          dataLength={item?.details?.length}
                          regWall={data?.regWall}
                        />
                      </FallbackContainer>
                    ))}
                  </div>
                </div>
              </Accordion>
            </FallbackContainer>
          ))}
        <div className={style.accordionProductContributionSpacing}>
          <FallbackContainer
            component="AccordionHeader"
            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
            <AccordionHeader
              hasBackground
              icon={<Barcode />}
              title={'Product contribution'}
              accordionHeaderTitle
            />
          </FallbackContainer>
        </div>
        {!!data?.product_contributions &&
          data?.product_contributions?.map((productContributions: any, index: number) => (
            <div className={style.accorionContribution}>
              <FallbackContainer
                component="Accordion"
                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                <Accordion
                  key={index}
                  isContributionProject
                  headerContent={
                    <FallbackContainer
                      component="AccordionHeader"
                      showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                      <AccordionHeader
                        key={`header-${index}`}
                        image={productContributions?.product_details?.thumbnail || ''}
                        title={productContributions?.product_details?.brand_name || ''}
                        name={productContributions?.product_details?.name || ''}
                        productUrl={productContributions?.product_details?.product_url || ''}
                        id={`${productContributions?.product_details?.brand_name}_${productContributions?.product_details?.name}`}
                      />
                    </FallbackContainer>
                  }>
                  <>
                    {!!productContributions?.calculators &&
                      productContributions?.calculators?.map((item: any, index: number) => (
                        <>
                          <FallbackContainer
                            component="ProductContributionGridHeader"
                            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                            <ProductContributionGridHeader
                              key={`header-${index}`}
                              name={item?.name || ''}
                              icon={gridIcons[item?.name] || ''}
                              lensDetails={item.details || []}
                            />
                          </FallbackContainer>
                          <div>
                            <div className={style.productTable}>
                              <AccordionRow
                                value={'values based on project parameters'}
                                customAccordionRow
                                regWall={data?.regWall}
                              />
                              {item.details?.map((row: any, evalIndex: number) => (
                                <FallbackContainer
                                  component="AccordionRow"
                                  showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                  setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                  <AccordionRow
                                    title={row?.name || ''}
                                    value={row?.value || ''}
                                    type={typeof row?.value || ''}
                                    key={`row-${evalIndex}`}
                                    isLightContent
                                    index={evalIndex}
                                    dataLength={item?.details?.length}
                                    regWall={data?.regWall}
                                  />
                                </FallbackContainer>
                              ))}
                            </div>
                          </div>
                        </>
                      ))}
                    {!!productContributions?.evaluations &&
                      productContributions?.evaluations.map(
                        (item: any, evaluationIndex: number) => (
                          <div>
                            {productContributions?.evaluations[evaluationIndex]?.details && (
                              <FallbackContainer
                                component="Accordion"
                                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                <Accordion
                                  key={evaluationIndex}
                                  isEvaluation
                                  headerContent={
                                    <FallbackContainer
                                      component="AccordionHeader"
                                      showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                      <AccordionHeader
                                        icon={gridIcons[item?.icon] || <UnitsIcon />}
                                        title={item?.name || ''}
                                        key={`${item?.name}-${evaluationIndex}`}
                                      />
                                    </FallbackContainer>
                                  }>
                                  <div>
                                    <div className={style.productTable}>
                                      {item.details?.map((row: any, evalIndex: number) => (
                                        <>
                                          <FallbackContainer
                                            component="AccordionRow"
                                            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                            <AccordionRow
                                              title={row?.name || ''}
                                              value={row?.value || ''}
                                              type={typeof row?.value || ''}
                                              key={`row-${evalIndex}`}
                                              isLightContent
                                              creditName={row?.display_id || ''}
                                              regWall={data?.regWall}
                                            />
                                          </FallbackContainer>
                                          {item?.details[evalIndex]?.details?.map((row: any) => (
                                            <FallbackContainer
                                              component="AccordionRow"
                                              showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                              setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                              <AccordionRow
                                                title={row?.name || ''}
                                                value={row?.value || ''}
                                                type={row?.kind || ''}
                                                key={`data-${evalIndex}`}
                                                isLightContent
                                                isCreditDetails
                                                regWall={data?.regWall}
                                              />
                                            </FallbackContainer>
                                          ))}
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </Accordion>
                              </FallbackContainer>
                            )}
                          </div>
                        )
                      )}
                    {!!productContributions?.lens_data &&
                      productContributions?.lens_data.map(
                        (lensValue: any, lensIndex: number): any => (
                          <div key={`lens-${index}-${lensIndex}`}>
                            {lensValue.details.details?.length > 0 ||
                            lensValue.details?.locations.length > 0 ? (
                              <FallbackContainer
                                component="ProductContributionGridHeader"
                                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                <ProductContributionGridHeader
                                  key={`header-${index}-${lensIndex}`}
                                  name={lensValue?.name || ''}
                                  image={lensValue?.image || ''}
                                  notes={lensValue?.notes || ''}
                                  lensDetails={lensValue?.details?.details || []}
                                />
                              </FallbackContainer>
                            ) : (
                              <div></div>
                            )}
                            <div className={style.productTable}>
                              {lensValue.details?.details
                                ? lensValue.details.details?.map(
                                    (detailsData: any, detailsIndex: number) => (
                                      <FallbackContainer
                                        component="AccordionRow"
                                        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                        <AccordionRow
                                          key={`row-${detailsData}-${detailsIndex}`}
                                          title={detailsData?.name || ''}
                                          value={detailsData?.value || ''}
                                          type={detailsData?.kind || ''}
                                          {...(detailsData?.hint && {
                                            tooltipText: detailsData?.hint
                                          })}
                                          isLensData
                                          isLightContent
                                          regWall={data?.regWall}
                                        />
                                      </FallbackContainer>
                                    )
                                  )
                                : lensValue.details?.locations.length > 0 &&
                                  lensValue.details?.locations?.map(
                                    (locationDetails: any, locationIndex: number) => (
                                      <FallbackContainer
                                        component="AccordionRow"
                                        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                        <AccordionRow
                                          key={`row-${locationDetails}-${locationIndex}`}
                                          city={locationDetails?.city || ''}
                                          postRc={locationDetails?.post_rc || ''}
                                          preRc={locationDetails?.pre_rc || ''}
                                          state={locationDetails?.state || ''}
                                          zipcode={locationDetails?.zipcode || ''}
                                          index={locationIndex}
                                          isManufactureLocation
                                          regWall={data?.regWall}
                                        />
                                      </FallbackContainer>
                                    )
                                  )}
                            </div>
                          </div>
                        )
                      )}
                  </>
                </Accordion>
              </FallbackContainer>
            </div>
          ))}
      </div>
      {isTopScroller && (
        <TopScroller>
          <IoIosArrowRoundUp />
        </TopScroller>
      )}
    </div>
  );
};

export default ProjectEvaluation;
