// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kXaj8kSKswXTe86svK7h{width:95% !important;margin:auto}.YBFmDe1nSllW0p4k1T_y{width:75% !important;margin:0;padding:0;margin-left:-3rem !important;margin-top:1rem}`, "",{"version":3,"sources":["webpack://./src/components/shared/Container/container.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CACA,WAAA,CAGJ,sBACI,oBAAA,CACA,QAAA,CACA,SAAA,CACA,4BAAA,CACA,eAAA","sourcesContent":[".container {\n    width: 95% !important;\n    margin: auto;\n}\n\n.aiSearchcontainer {\n    width: 75% !important;\n    margin: 0;\n    padding: 0;\n    margin-left: -3rem !important;\n    margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kXaj8kSKswXTe86svK7h`,
	"aiSearchcontainer": `YBFmDe1nSllW0p4k1T_y`
};
export default ___CSS_LOADER_EXPORT___;
