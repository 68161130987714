import React from 'react';

interface SantizedHTMLRawDataProps {
  content?: string;
}
const SantizedHTMLRawData: React.FC<SantizedHTMLRawDataProps> = ({ content }) => {
  if (content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return <div></div>;
};

export default SantizedHTMLRawData;
